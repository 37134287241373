<template>
  <div class="upw-edit">
    <div class="upw-edit__detail">
      <general-information
        :isEdit.sync="isEdit"
        :generalInfo="form"
        className="upw-edit__info"
        :showSave="canEditUserInfo"
        @form-update="onFormUpdate"
      />
      <div class="upw-edit__role">
        <h6>Role</h6>
        <div class="upw-edit__div">
          <b-row>
            <b-col>
              <LxpRadio
                text="Staff"
                value="staff"
                :selectedVal="form.role"
                name="user-role"
                id="user-role-staff"
                @input="onInput"
                :disabled="disableStaffRole"
              />
              <p class="upw-edit__note">
                Designates whether the user can log into this admin console
                site.
              </p>
            </b-col>
            <b-col>
              <LxpRadio
                text="Admin (Superuser)"
                value="admin"
                :selectedVal="form.role"
                name="user-role"
                id="user-role-admin"
                @input="onInput"
                :disabled="disabledAdminRole"
              />
              <p class="upw-edit__note">
                Designates that this user has all permissions without explicitly
                assigning them.
              </p>
            </b-col>
          </b-row>
        </div>
      </div>
      <div class="upw-edit__status">
        <h6>Status</h6>
        <div class="upw-edit__status--div">
          <LxpToggleSwitch
            :toggleState.sync="form.is_active"
            :disabled="!isEdit"
          />
          <div class="upw-edit__status--right">
            <label>Active</label>
            <p class="upw-edit__note">
              Designates whether this user should be treated as active. Unselect
              this instead of deleting accounts.
            </p>
          </div>
        </div>
      </div>
    </div>
    <div class="upw-edit__cta" v-if="isEdit">
      <LxpButton variant="link" @click="onCancel">Cancel</LxpButton>
      <LxpButton variant="secondary1" :disabled="disableSaveBtn" @click="onSave"
        >Save</LxpButton
      >
    </div>
  </div>
</template>

<script>
import { LxpButton, LxpRadio, LxpToggleSwitch } from "didactica";
import GeneralInformation from "../Shared/GeneralInformation.vue";
import { mapGetters } from "vuex";
export default {
  components: {
    LxpButton,
    LxpRadio,
    LxpToggleSwitch,
    GeneralInformation
  },
  data() {
    return {
      isEdit: false,
      options: [
        { text: "Staff", value: "staff" },
        { text: "Admin(Superuser)", value: "admin" }
      ],
      form: {}
    };
  },
  computed: {
    ...mapGetters(["currentUser", "userAccountInfo"]),
    id() {
      return this.$route.params.id;
    },
    canEditUserInfo() {
      return !this.isEdit && this.userAccountInfo?.id !== this.currentUser?.id;
    },
    disableStaffRole() {
      return !this.isEdit && this.form.role !== "staff";
    },
    disabledAdminRole() {
      return !this.isEdit && this.form.role !== "admin";
    },
    disableSaveBtn() {
      let disable = false;
      Object.keys(this.form).forEach(f => {
        if (this.form[f] === "") {
          disable = true;
        }
      });
      return disable;
    }
  },
  watch: {
    currentUser() {
      this.setFormVal();
    }
  },
  created() {
    if (this.currentUser === null) {
      this.$store.dispatch("getUserDetails", { id: this.id });
    } else {
      this.setFormVal();
    }
  },
  methods: {
    setFormVal() {
      this.form = this.currentUser;
    },
    onSave() {
      this.$store.dispatch("updateUserDetails", this.form).then(() => {
        this.$router.push({ name: "UserList" });
      });
    },
    onInput(e) {
      this.form.role = e;
    },
    onFormUpdate(data) {
      this.form = data;
    },
    onCancel() {
      this.setFormVal();
      this.isEdit = false;
    }
  }
};
</script>

<style lang="scss" scoped>
.upw-edit {
  .upw-edit__detail {
    @include account-style;
    .upw-edit__info {
      padding: 0;
      box-shadow: none;
      .sw-account__info--heading {
        img {
          display: none;
        }
      }
    }
    .upw-edit__role,
    .upw-edit__status {
      margin-bottom: 16px;
      // @include account-style;
      .upw-edit__note {
        @include label-medium;
        color: $brand-neutral-300;
        margin: 8px 0 0 0;
      }
      .upw-edit__status--div {
        display: flex;
        align-items: center;
        label {
          @include label-large($brand-primary);
          margin-bottom: 0;
        }
        .upw-edit__note {
          margin-top: 0;
        }
        .upw-edit__status--right {
          margin-left: 8px;
        }
      }
    }
  }
  .upw-edit__cta {
    margin-top: 8px;
    display: flex;
    align-items: center;
    justify-content: flex-end;
    .lxp-button {
      &:first-child {
        margin-right: 16px;
      }
    }
  }
}
</style>
